
.rounded-full {
  border-radius: 9999px;
}

.w-12 {
  width: 3rem;
}

.h-12 {
  height: 3rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}
.font-medium {
  font-weight: 400;
}